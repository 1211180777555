.contact-us-section {
    margin-top: 2px;
    /* margin-bottom: 300px; */
  }
  
  .contact-background {
    background-image: linear-gradient(90deg, #32157580 0%, #8d0b938a 51%, #ff00665e 100%, #ff057c96 100%);
    padding: 50px 20px;
    margin-bottom: 0;
    z-index: 10;
    position: relative;
    color: white;
  }
  
  .section-title {
    font-size: 36px;
    margin-top: 0;
    font-weight: 700;
    text-align: center;
    border-bottom: 5px solid #d5d5d5;
    padding-bottom: 10px;
    margin-bottom: 30px;
    color: #000; /* Make the title text dark */
  }
  
  .contact-details {
    display: flex;
    justify-content: space-around;
  }
  
  .contact-person {
    text-align: center;
  }
  
  .person-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .person-role {
    font-style: italic;
    margin-bottom: 5px;
  }
  
  .person-info {
    font-size: 16px;
  }
  
  .person-info a {
    color: white;
    text-decoration: underline;
  }
  