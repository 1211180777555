/* src/components/Venue.css */

.venue-page {
    margin-top: 0px;
    min-height: 100vh;
    padding-bottom: 32px;
    padding-top: 32px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    background-position: center;
}

.venue-top-image {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.venue-top-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.venue-row {
    display: flex;
    /* justify-content: center; */
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 16px;
}

.venue-box {
    background-color: white;
    padding: 30px;
    box-shadow: 0px 0px 16px 0px #757575b3;
    border-radius: 10px;
    margin: 8px;
    width: 100%;
    text-align: center;
}

.about-iiitm {
    background-color: white;
    padding: 30px;
    box-shadow: 0px 0px 16px 0px #757575b3;
    border-radius: 10px;
    margin: 8px;
    width: 100%;
    /* text-align: center; */
}