.important-dates-section {
  margin-top: 2px;
}

.dates-background {
  background-image: linear-gradient(90deg, #32157580 0%, #8d0b938a 51%, #ff00665e 100%, #ff057c96 100%);
  padding: 50px 20px;
  margin-bottom: 0;
  z-index: 10;
  position: relative;
  color: white;
}

.section-title {
  font-size: 36px;
  margin-top: 0;
  font-weight: 200;
  text-align: center;
  border-bottom: 5px solid #d5d5d5;
  padding-bottom: 10px;
  margin-bottom: 30px;
  color: #000;
}

.dates-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.date-item {
  font-size: 18px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 10px;
  color: #000;
}

.date {
  font-weight: bold;
  margin-right: 10px;
}

.event {
  font-style: italic;
}

/* Red strikethrough for the extended date */
.red-strikethrough {
  text-decoration: line-through;
  text-decoration-color: red;
  color: #757575;
}
