/* src/components/Header.css */

/* General Header Styles */
.header {
  font-size: 20px;
  /* Example larger font size */
  font-weight: bold;
  /* Make text bold */
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: gray.800;
  /* Ensure the background covers the content below */
  width: 100%;
}

.navbar {
  display: flex;
  align-items: center;
}

.nav-links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li:last-child {
  margin-right: 0;
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #66C6F1;
  /* Example hover color */
}

/* Styles for Drawer */
.drawer-content {
  padding: 20px;
}

.drawer-content a {
  display: block;
  padding: 10px 0;
  color: black;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.drawer-content a:hover {
  background-color: #F3F3F3;
  /* Example hover background color */
}