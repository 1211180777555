/* General Page Styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f9f9f9;
}

.registration-venue-page {
    background-size: cover;
    background-position: center;
    padding: 40px 20px;
    min-height: 100vh;
}

/* Container Styling */
.registration-page-container {
    max-width: 1000px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

/* Heading Styling */
.registration-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
}

/* Tab Navigation Styling */
.registration-tab-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    border-bottom: 2px solid #ddd;
}

.registration-tab-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #555;
    background: none;
    border: none;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease;
}

.registration-tab-button:hover {
    color: #e72f0e;
}

.registration-tab-button.active {
    font-weight: bold;
    color: #e72f0e;
    border-bottom: 2px solid #e72f0e;
}

/* Tab Content Styling */
.registration-tab-content {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
}

/* Table Styling */
.registration-table {
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;
    border: 2px solid #ddd;
    background-color: #fff;
}

.registration-table th,
.registration-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
}

.registration-table th {
    background-color: #f1f1f1;
    font-weight: bold;
}

.registration-table td {
    font-size: 16px;
}

/* Notes Section Styling */
.registration-notes-container {
    margin-top: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.registration-note-text {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.registration-note-list {
    list-style: disc;
    margin-left: 20px;
    color: #555;
    font-size: 16px;
}

/* Link Styling */
a {
    color: #e72f0e;
    text-decoration: none;
    transition: color 0.3s ease;
}

a:hover {
    color: #b82208;
}

/* Responsive Design */
@media (max-width: 768px) {
    .registration-page-container {
        padding: 15px;
    }

    .registration-tab-button {
        padding: 8px 12px;
        font-size: 14px;
    }

    .registration-heading {
        font-size: 20px;
    }

    .registration-table th,
    .registration-table td {
        font-size: 14px;
        padding: 8px;
    }

    .registration-tab-content {
        padding: 15px;
    }
}